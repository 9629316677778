import React, { lazy, Suspense, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { AuthProvider } from './contents/router/Auth/AuthContext';
import { Helmet } from 'react-helmet';

import Test from './contents/views/front/page/contents/test.js';
import { AuthProviderBoffice } from './contents/router/Auth/BofficeAuthContext.js';
import i18n from './locales/i18n';

const BofficeRouter = lazy(() => import('./contents/router/BofficeRouter'));
// const MainRouter = lazy(() => import('./contents/router/FrontRouter'));
// const FrontRouter = lazy(() => import('./contents/router/HtmlRouter'));
const DownloadRouter = lazy(() => import('./contents/router/DownloadRouter'));

const FrontTwoRouter = lazy(() => import('./contents/router/FrontTwoRouter'));

function App() {
    const { pathname } = window.location;

    const [language, setLanguage] = useState('en');

    useEffect(() => {
      if (typeof window !== 'undefined' && window.navigator) {
        const browserLang = window.navigator.language || window.navigator.userLanguage;
        const StorageLan = localStorage.getItem('language');
        if(!StorageLan){
            localStorage.setItem('language', browserLang.split('-')[0]);
        }
        const Lang = StorageLan ? StorageLan : browserLang.split('-')[0];
        i18n.changeLanguage(Lang);
        console.log(Lang);
        setLanguage(Lang);
      }

    //   if (pathname === '/') {
    //     window.location.replace('/Main');
    //     }
    }, [pathname]);

    let routerComponent;


    if (pathname.toLowerCase().startsWith('/boffice')) {
        console.log('boffice!!!');
        routerComponent = (
            <AuthProviderBoffice>
                <BofficeRouter />
            </AuthProviderBoffice>
        );
    } else if (pathname.toLowerCase().startsWith('/front')) {
        console.log('front!!!');
        routerComponent = <FrontTwoRouter />;
    } 
    // else if (pathname.toLowerCase().startsWith('/new_front')) {
    //     console.log('fronttwo!!!');
    //     routerComponent = <AuthProvider><FrontTwoRouter /></AuthProvider>;
    // } 
    else if (pathname.toLowerCase().startsWith('/test')) {
        console.log('test!!!');
        routerComponent = <Test />;
    } else if (pathname.toLowerCase().startsWith('/download')) {
        console.log('download!!!');
        routerComponent = <DownloadRouter />;
    } 
    else {
        console.log('main!!!');
        routerComponent = (
            <AuthProvider>
                <FrontTwoRouter />
            </AuthProvider>
        );
    }

    return <Suspense>
            <Helmet>
                <html lang={language} />
            </Helmet>
        {routerComponent}
        </Suspense>;
}

export default App;
